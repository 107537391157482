import pick from 'lodash/pick';

import { AjvUtil } from '../utils/ajv-util';
import FormStore from '../classes/form-store';
import { USER_MODEL_EDITABLE_PROPERTIES, UserShape } from '../models/user';
import { setUserGroups } from '../redux/actions/users';
import { UsersApi } from '../api/users-api';
import { usersByIdSelector } from '../redux/selectors/users';

import { PreloadedGroupsStore } from './preloaded-groups-store';
import { PreloadedUsersStore } from './preloaded-users-store';

const UserValidation = AjvUtil.compileSchema({
  required: ['isActive', 'roles'],
  properties: {
    isActive: {
      type: 'boolean',
    },
    roles: {
      type: 'string',
      minLength: 1,
      maxLength: 255,
    },
  },
});

class FormUserManagementStoreClass extends FormStore {
  load({ email, firstName, id, isActive, isSSO, lastName, roles, team }) {
    return this.setState({
      email,
      firstName,
      id,
      isActive,
      isSSO,
      lastName,
      roles,
      team,
    });
  }

  getDataShape() {
    return UserShape;
  }

  validate(formData) {
    return AjvUtil.formatValidationForFinalForm(UserValidation, formData);
  }

  async submit(_formData, form) {
    const safeValues = pick(form, USER_MODEL_EDITABLE_PROPERTIES);
    safeValues.id = form.id;
    // This field goes to a different endpoint, and will cause
    // a server-side validation error if submitted here.
    delete safeValues.team;
    if (!!safeValues.roles && !Array.isArray(safeValues.roles)) {
      safeValues.roles = [safeValues.roles];
    }
    await UsersApi.updateUser(safeValues);

    // TODO: migrate logic from redux sagas
    this.dispatch(setUserGroups(form));

    setTimeout(() => {
      PreloadedGroupsStore.load();
      PreloadedUsersStore.load();
    }, 500);
  }
}

export const FormUserManagementStore = new FormUserManagementStoreClass();
