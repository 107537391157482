import React, { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalSection,
  Button,
  Layout,
  GridRow,
  Grid,
  GridColumn,
  LayoutItem,
  IconButton,
} from '@axiom/ui';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import FormStateTreatment from '../FormStateTreatment/FormStateTreatment';
import FFMultiSelect from '../FFMultiSelect/FFMultiSelect';
import DropdownList from '../DropdownList/DropdownList';
import FFDatePicker from '../FFDatePicker/FFDatePicker';
import { FFInput } from '../FFInput/FFInput';
import { opportunityEditLanguagesTexts } from '../../utils/languages-util';
import { formatDataTestId } from '../../utils/data-test-util';
import { DropdownOptionsShape } from '../../models/dropdown-options';
import { PreloadedUsersStore } from '../../stores/preloaded-users-store';
import { PreloadedLanguagesStore } from '../../stores/preloaded-languages-store';
import { FormOpportunityProfileDetailStore } from '../../stores/form-opportunity-profile-detail-store';
import { FFDropdown } from '../FFDropdown/FFDropdown';

import {
  forecastItems,
  languageProficiencyItems,
  languageSkillItems,
} from './opportunity-edit-form-const';

const OpportunityEditForm = props => {
  const { usersOptions, languages } = props;
  const [languageId, setLanguageId] = useState(null);
  const [proficiency, setProficiency] = useState(null);

  const handleAddLanguage = (push, skill = null) => {
    const row = {
      id: languageId,
      requiredLanguageProficiency: proficiency,
      requiredLanguageSkill: skill,
    };

    push('languages', row);

    setLanguageId(null);
    setProficiency(null);
  };

  return (
    <FormStateTreatment
      formStore={FormOpportunityProfileDetailStore}
      isModalState
      renderLoadedView={({
        formData,
        fireSubmit,
        fireCancel,
        form: {
          mutators: { push },
        },
      }) => (
        <Modal size="large">
          <ModalHeader onClose={fireCancel}>Edit Opportunity</ModalHeader>
          <ModalSection>
            <Grid>
              <GridRow gutterBottom="16px">
                <GridColumn width={4}>
                  <Field
                    name="opportunityName"
                    component={FFInput}
                    label="Opportunity Name:"
                    disabled
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <Field
                    showAftIcon
                    name="accountName"
                    component={FFInput}
                    label="Account Name:"
                    disabled
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <Field
                    showAftIcon
                    name="clientName"
                    component={FFInput}
                    label="Client Name:"
                    placeholder={formData.clientName || ''}
                    disabled
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="16px">
                <GridColumn width={4}>
                  {' '}
                  <Field
                    name="stage"
                    component={FFInput}
                    label="Opportunity Stage:"
                    disabled
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <Field
                    name="offering"
                    component={FFInput}
                    label="Type:"
                    disabled
                  />
                </GridColumn>
                <GridColumn width={4}>
                  {' '}
                  <Field
                    name="salesCloseDate"
                    id="OpportunityEditForm-salesCloseDate"
                    component={FFDatePicker}
                    label="Close Date:"
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="16px">
                <GridColumn width={4}>
                  <Field
                    showAftIcon
                    name="ownerUserId"
                    component={FFDropdown}
                    options={usersOptions}
                    label="TS Lead:"
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <Field
                    showAftIcon
                    name="salesLeadId"
                    component={FFDropdown}
                    options={usersOptions}
                    label="Opportunity Owner:"
                  />
                </GridColumn>
                <GridColumn width={4}>
                  {' '}
                  <Field
                    showAftIcon
                    name="salesforceCxLeadId"
                    component={FFDropdown}
                    options={usersOptions}
                    label="Account Manager:"
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="16px">
                <GridColumn width={4}>
                  <Field
                    name="currency"
                    component={FFInput}
                    label="Currency:"
                    disabled
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <Field
                    name="forecastCategory"
                    component={FFDropdown}
                    label="Forecast Category:"
                    options={forecastItems}
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="16px">
                <GridColumn width={4}>
                  <Field
                    name="collaboratorId"
                    component={FFMultiSelect}
                    label="Opportunity Collaborators:"
                    options={usersOptions}
                  />
                </GridColumn>
              </GridRow>
              <GridRow gutterBottom="16px">
                <GridColumn width={12}>
                  <Layout position="middle" horizontalGutter="8px">
                    <LayoutItem fluid>
                      <DropdownList
                        label="Language:"
                        name="language"
                        onChange={setLanguageId}
                        options={languages}
                        value={languageId}
                      />
                    </LayoutItem>
                    <LayoutItem fluid>
                      <DropdownList
                        label="Language Proficiency:"
                        disabled={!languageId}
                        name="proficiency"
                        onChange={setProficiency}
                        options={languageProficiencyItems}
                        value={proficiency}
                      />
                    </LayoutItem>
                    <LayoutItem fluid>
                      <DropdownList
                        label="Language Skill:"
                        disabled={!proficiency}
                        name="skill"
                        onChange={val => {
                          handleAddLanguage(push, val);
                        }}
                        options={languageSkillItems}
                        value={null}
                      />
                    </LayoutItem>
                    <LayoutItem>
                      <IconButton
                        variation="minimal"
                        pattern="secondary"
                        icon="plus"
                        disabled={!proficiency}
                        onClick={() => handleAddLanguage(push)}
                        name="ADDLANGUAGEBUTTON"
                      />
                    </LayoutItem>
                  </Layout>
                </GridColumn>
              </GridRow>
              <FieldArray name="languages">
                {({ fields }) => {
                  const languagesMap = opportunityEditLanguagesTexts({
                    opportunityLanguages: fields.value,
                    languages,
                  });

                  return fields.map((name, index) => {
                    const language = fields.value[index];
                    const key =
                      language.id +
                      language.requiredLanguageProficiency +
                      language.requiredLanguageSkill;

                    return (
                      <GridRow
                        gutterBottom="16px"
                        key={name}
                        name="SELECTED_LANGUAGES"
                      >
                        <GridColumn width={12}>
                          <Layout position="middle space-between">
                            <Field
                              data-test={formatDataTestId('language_name')}
                              name={`${name}.id`}
                              component="input"
                              hidden
                            />
                            <Field
                              data-test={formatDataTestId(
                                'language_procifiency'
                              )}
                              name={`${name}.languageProficiency`}
                              component="input"
                              hidden
                            />
                            <Field
                              data-test={formatDataTestId('language_skill')}
                              name={`${name}.languageSkill`}
                              component="input"
                              hidden
                            />
                            {languagesMap[key].language}
                            <IconButton
                              variation="minimal"
                              pattern="secondary"
                              onClick={() => fields.remove(index)}
                              name="DELETE_LANGUAGE"
                              icon="trash"
                            />
                          </Layout>
                        </GridColumn>
                      </GridRow>
                    );
                  });
                }}
              </FieldArray>
            </Grid>
          </ModalSection>
          <ModalFooter>
            <Button
              className="profile-edit-action"
              name="OPPORTUNITUYEDITFORMCANCELBUTTON"
              onClick={fireCancel}
              variation="outline"
            >
              Cancel
            </Button>
            <Button
              className="profile-edit-action"
              name="OPPORTUNITYEDITFORMSAVEBUTTON"
              onClick={fireSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      )}
    />
  );
};

OpportunityEditForm.propTypes = {
  languages: PreloadedLanguagesStore.getDataShape().isRequired,
  usersOptions: DropdownOptionsShape.isRequired,
};

export default connect(state => ({
  usersOptions: PreloadedUsersStore.selectData(state).map(user => ({
    label: user.fullName,
    value: user.id,
  })),
}))(OpportunityEditForm);
