import { Api } from '@axiom/ui';
import { ContactLegacy, User } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class UsersApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  writeResendUserWelcomeEmail(contactId: ContactLegacy['id']) {
    return super.write<{ data: User }>({
      endpoint: `/users/resendUserWelcomeEmail`,
      method: 'POST',
      body: { contactId },
    });
  }
}

export const UsersApi = new UsersApiClass();
