import { CandidatesConst } from '@axiom/const';
import { EnvoyOccupationTypeValues } from '@axiom/validation';

import { CandidateUtil } from '../../utils/candidate-util';

const { EmployeeTypes, WorkPreferences } = CandidatesConst;

export const talentProfileStatusSelectItems =
  CandidateUtil.getProfileStatusSelectItems();

const formatOptions = (options: string[]) =>
  options.map((option: string) => ({ value: option, label: option }));

export const jobProfileOptions = formatOptions(EnvoyOccupationTypeValues);

export const employeeTypeOptions = formatOptions(Object.values(EmployeeTypes));

export const workPreferencesOptions = Object.entries(WorkPreferences).map(
  ([value, label]) => ({
    label,
    value,
  })
);
