import moment from 'moment';
import groupBy from 'lodash/groupBy';
import {
  ProfileCompletionConst,
  CandidateProfileIssueConst,
  CandidateOpportunitiesConst,
  PositionsConst,
  CandidatesConst,
  CandidateProfileIssueExperienceValue,
} from '@axiom/const';
import type {
  Candidate,
  CandidateOpportunity,
  CandidateProfileIssue,
  Experience,
} from '@axiom/validation';

export const CandidateProfileIssuesUtils = {
  experienceHasTooLongDescription(experience: Experience) {
    return (
      (experience.description?.length ?? 0) >
      ProfileCompletionConst.maximumAcceptableExperienceDescriptionLength
    );
  },
  getExperienceDescriptionGuidanceIssue(
    issues?: CandidateProfileIssue[] | null,
    experienceId?: Experience['id']
  ) {
    return issues?.find(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
        issue.issue ===
          CandidateProfileIssueConst.CandidateProfileIssue.Content &&
        Object.keys(
          ProfileCompletionConst.ExperienceDescriptionBannerConfigByCandidateProfileIssueContext
        ).includes(issue.context) &&
        issue.entityId === (experienceId ?? '')
    ) as Omit<CandidateProfileIssue, 'context'> & {
      context: CandidateProfileIssueExperienceValue;
    };
  },
  hasPracticeStartYearIssues(issues?: CandidateProfileIssue[] | null) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory
            .PracticeStartYear &&
        issue.issue === CandidateProfileIssueConst.CandidateProfileIssue.Missing
    );
  },

  hasValidNameIssues(issues?: CandidateProfileIssue[] | null) {
    return CandidateProfileIssuesUtils.getNameErrors(issues).length > 0;
  },

  getNameErrors(issues?: CandidateProfileIssue[] | null) {
    const errors = [];

    // Check if there are too many capital letters in the name
    if (
      issues?.some(
        issue =>
          issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueName.AllCaps
      )
    ) {
      errors.push(ProfileCompletionConst.ProfileCompletionNameBanner.allCaps);
    }

    // Check for specified qualifiers, 3 or more consecutive capital letters are included
    if (
      issues?.some(
        issue =>
          issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueName.Qualifications
      )
    ) {
      errors.push(
        ProfileCompletionConst.ProfileCompletionNameBanner.qualifications
      );
    }

    // Check if there are any titles in the name (title check is in beginning)
    if (
      issues?.some(
        issue =>
          issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueName.Titles
      )
    ) {
      errors.push(ProfileCompletionConst.ProfileCompletionNameBanner.titles);
    }

    // Check if there are any nicknames in the name
    if (
      issues?.some(
        issue =>
          issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueName.Nicknames
      )
    ) {
      errors.push(ProfileCompletionConst.ProfileCompletionNameBanner.nicknames);
    }

    return errors;
  },

  hasSummaryOfAnyLengthIssues(issues?: CandidateProfileIssue[] | null) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.Summary &&
        issue.issue === CandidateProfileIssueConst.CandidateProfileIssue.Missing
    );
  },

  hasSummaryOfSufficientLengthIssues(issues?: CandidateProfileIssue[] | null) {
    return (
      !!issues?.some(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Summary &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.LengthShort
      ) && !CandidateProfileIssuesUtils.hasSummaryOfAnyLengthIssues(issues)
    );
  },

  hasNonAxiomExperienceIssues(issues?: CandidateProfileIssue[] | null) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
        issue.issue ===
          CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
        issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueExperience
            .NonAxiomMissing
    );
  },

  hasExperienceWithMissingIndustryIssues(
    issues?: CandidateProfileIssue[] | null
  ) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
        issue.issue ===
          CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
        issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueExperience
            .MissingIndustry
    );
  },

  hasExperienceWithMissingIndustryIssue(
    issues?: CandidateProfileIssue[] | null,
    experienceId?: string | null
  ) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
        issue.issue ===
          CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
        issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueExperience
            .MissingIndustry &&
        issue.entityId === (experienceId ?? '')
    );
  },

  hasExperienceWithMissingTaxonomyIssue(
    issues?: CandidateProfileIssue[] | null,
    experienceId?: string | null
  ) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
        issue.issue ===
          CandidateProfileIssueConst.CandidateProfileIssue.Missing &&
        issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueExperience
            .MissingTaxonomy &&
        issue.entityId === (experienceId ?? '')
    );
  },

  hasAxiomExperienceWithBlankDescriptionIssues(
    issues?: CandidateProfileIssue[] | null
  ) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
        issue.issue ===
          CandidateProfileIssueConst.CandidateProfileIssue.Content &&
        issue.context ===
          CandidateProfileIssueConst.CandidateProfileIssueExperience
            .AxiomBlankDescription
    );
  },

  hasLegalTechSkillsIssues(issues?: CandidateProfileIssue[] | null) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.LegalTechSkills &&
        issue.issue === CandidateProfileIssueConst.CandidateProfileIssue.Missing
    );
  },

  hasDegreesIssues(issues?: CandidateProfileIssue[] | null) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.Education &&
        issue.issue === CandidateProfileIssueConst.CandidateProfileIssue.Missing
    );
  },

  hasNativeLanguageIssues(issues?: CandidateProfileIssue[] | null) {
    return !!issues?.some(
      issue =>
        issue.category ===
          CandidateProfileIssueConst.CandidateProfileCategory.Languages &&
        issue.issue === CandidateProfileIssueConst.CandidateProfileIssue.Missing
    );
  },

  hasPreferencesIssues(issues?: CandidateProfileIssue[] | null) {
    return CandidateProfileIssuesUtils.getPreferencesIssues(issues).length > 0;
  },

  getPreferencesIssues(issues?: CandidateProfileIssue[] | null) {
    return (
      issues?.filter(
        issue =>
          issue.category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Preferences &&
          issue.issue ===
            CandidateProfileIssueConst.CandidateProfileIssue.Outdated
      ) ?? []
    );
  },

  filterExperienceSpecificIssues(issues?: CandidateProfileIssue[] | null) {
    return (
      issues?.filter(
        ({ category, entityId }) =>
          category ===
            CandidateProfileIssueConst.CandidateProfileCategory.Experience &&
          !!entityId
      ) ?? []
    );
  },

  hasExperienceSpecificIssues(issues?: CandidateProfileIssue[] | null) {
    return (
      CandidateProfileIssuesUtils.filterExperienceSpecificIssues(issues)
        .length > 0
    );
  },

  getExperienceIssues(
    issues?: CandidateProfileIssue[] | null,
    experiences: Experience[] = []
  ) {
    const experiencesById = experiences.reduce<
      Record<Experience['id'], Experience>
    >((acc, exp) => {
      acc[exp.id] = exp;
      return acc;
    }, {});

    const experienceIssues =
      CandidateProfileIssuesUtils.filterExperienceSpecificIssues(issues);
    const groupedByEntityId = groupBy(experienceIssues, 'entityId');

    return Object.keys(groupedByEntityId).map(id => {
      return {
        id,
        calculatedDisplayName: experiencesById[id]?.calculatedDisplayName,
        issues: groupedByEntityId[id],
      };
    });
  },

  hasProfileIssues(issues?: CandidateProfileIssue[] | null) {
    return (
      CandidateProfileIssuesUtils.hasValidNameIssues(issues) ||
      CandidateProfileIssuesUtils.hasSummaryOfAnyLengthIssues(issues) ||
      CandidateProfileIssuesUtils.hasSummaryOfSufficientLengthIssues(issues) ||
      CandidateProfileIssuesUtils.hasExperienceSpecificIssues(issues) ||
      CandidateProfileIssuesUtils.hasNonAxiomExperienceIssues(issues) ||
      CandidateProfileIssuesUtils.hasLegalTechSkillsIssues(issues) ||
      CandidateProfileIssuesUtils.hasDegreesIssues(issues) ||
      CandidateProfileIssuesUtils.hasNativeLanguageIssues(issues) ||
      CandidateProfileIssuesUtils.hasPracticeStartYearIssues(issues) ||
      CandidateProfileIssuesUtils.hasPreferencesIssues(issues)
    );
  },

  shouldShowProfileCompletionSection(
    candidate: Candidate,
    candidateOpportunities: CandidateOpportunity[],
    issues: CandidateProfileIssue[] | null
  ) {
    const excludedByProfileStatus = [
      CandidatesConst.ProfileStatuses.NewLead,
      CandidatesConst.ProfileStatuses.Certifying,
      CandidatesConst.ProfileStatuses.Reservoir,
    ].includes(candidate.profileStatus);
    const hasRollOffWithinSixWeeks = candidateOpportunities.some(
      ({ candidateStatus, position }) =>
        candidateStatus ===
          CandidateOpportunitiesConst.CandidateStatuses.Engaged &&
        position?.endDateStatus === PositionsConst.EndDateStatuses.Confirmed &&
        moment(position.endDate)
          .startOf('day')
          .isSameOrBefore(moment().add(6, 'weeks').startOf('day'))
    );
    return !!(
      !excludedByProfileStatus &&
      CandidateProfileIssuesUtils.hasProfileIssues(issues) &&
      (candidate.weeklyAvailability ||
        hasRollOffWithinSixWeeks ||
        candidate.isAccessibleToWorkFeed)
    );
  },
};
