import React, { useMemo, useState } from 'react';
import moment from 'moment-timezone';
import {
  CalendarEventType,
  Opportunity,
  User,
  CandidateRedux,
} from '@axiom/validation';
import {
  Grid,
  GridColumn,
  GridRow,
  ImageCircle,
  Layout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  SmallHeader,
  Calendar,
  CalendarPermissionsUtil,
  Button,
  LayoutItem,
  Gutter,
  Form,
  CalendarTimeZoneUtil,
  FormLabel,
  CondensedMedium,
  useBreakpoint,
  CandidateProfileUtil,
  CalendarEventsUtil,
  EventStates,
  Dropdown,
  Badge,
  Paragraph,
} from '@axiom/ui';
import { PossibleImageSizes, PermissionImpersonationRoles } from '@axiom/const';

import { CalendarApi } from '../../api/calendar-api';
import { OpportunityStore } from '../../stores/opportunity-store';
import { useRefreshCandidate } from '../../hooks/useRefreshCandidate';

import { TalentInterviewAvailabilityModalConfirm } from './TalentInterviewAvailabilityModalConfirm';
import { LegendKey } from './TalentInterviewAvailabilityModalStyles';
import { useTalentInterviewAvailabilityModalData } from './useTalentInterviewAvailabilityModalData';
import { useCanDoInterviewEvents } from './useCanDoInterviewEvents';
import {
  CalendarFormSchema,
  CalendarFormType,
} from './talent-interview-availability-modal-types';
import {
  DayWeekMode,
  CalendarScheduleModeOptions,
  CalendarScheduleModes,
} from './talent-interview-availability-modal-const';

export type TalentInterviewAvailabilityModalType = {
  opportunity?: Opportunity;
  position?: Opportunity['positions'][number];
  candidate: CandidateRedux;
  user: User;
  onClose: () => void;
};

export const TalentInterviewAvailabilityModal = ({
  candidate,
  user,
  onClose,
}: TalentInterviewAvailabilityModalType) => {
  const guessIana = moment.tz.guess();
  const { isMobile } = useBreakpoint();
  const [confirmModalOpen, setConfirmModalOpen] = useState([]);
  const { refreshCandidate } = useRefreshCandidate(candidate.id);
  const {
    changePermissionsToEditStaticEvent: changeInterviewPermissions,
    changePermissionsToEditEventBoundary: changeFreeTimePermissions,
  } = CalendarPermissionsUtil(user, PermissionImpersonationRoles.envoy);
  const { id: calendarId = null, timezone: talentIana } =
    candidate.calendar || {};
  const timezone = CalendarTimeZoneUtil.getTimeZone(talentIana || guessIana);
  const { opportunity, position, calendarData } =
    useTalentInterviewAvailabilityModalData(candidate);

  const formInitData = useMemo(() => {
    const { freeTimes, interviewTimes } = (calendarData.events || [])
      .filter(
        (event: CalendarEventType) =>
          !CalendarEventsUtil.isInThePast(
            event.endTime,
            changeFreeTimePermissions()
          )
      )
      .reduce(
        (crnt, item) => {
          item.state = EventStates.SAVED;

          if (item.busy) {
            crnt.interviewTimes.push(item);
          } else {
            crnt.freeTimes.push(item);
          }

          return crnt;
        },
        { freeTimes: [], interviewTimes: [] }
      );

    return {
      CALENDAR_COMPONENT: freeTimes,
      BACKGROUND_EVENTS: interviewTimes,
      MODE_CHANGER: CalendarScheduleModes.AVAILABILITY,
    };
  }, [calendarData.events]);

  const onModeChange = (
    mode: CalendarFormType['MODE_CHANGER'],
    values: CalendarFormType,
    setValues: (arg: React.SetStateAction<CalendarFormType>) => void
  ) => {
    /**
     * Per the extra empty objects in the setValues:
     *
     * setValues has bug
     *
     * When the value is changed from an
     * array of objects to an empty array the value
     * is not changed. When the value goes from
     * array of objects to another array of objects
     * it works as expected.
     *
     * The empty objects don't render and get stripped out
     * within the Calendar component.
     * */
    setValues({
      CALENDAR_COMPONENT: [...values.BACKGROUND_EVENTS, {}],
      BACKGROUND_EVENTS: [...values.CALENDAR_COMPONENT, {}],
      MODE_CHANGER: mode,
    });
  };

  const canDoInterviewEvents: boolean = useCanDoInterviewEvents({
    candidate,
    opportunity,
    position,
    user,
  });

  const refreshData = async () => {
    await Promise.all([
      CalendarApi.refreshCalendar(calendarId),
      CalendarApi.refreshCalendarEvents(calendarId),
      opportunity?.id ? OpportunityStore.load(opportunity.id) : null,
      refreshCandidate(candidate.id),
    ]);
  };

  const handleUpdateOnClick = async (
    { CALENDAR_COMPONENT, BACKGROUND_EVENTS, MODE_CHANGER }: CalendarFormType,
    {
      setFieldError,
    }: { setFieldError: (field: string, message: string) => void }
  ) => {
    const allEvents = [
      ...(CALENDAR_COMPONENT ?? []),
      ...(BACKGROUND_EVENTS ?? []),
    ];

    const activePermissions =
      MODE_CHANGER === CalendarScheduleModeOptions[0].id
        ? changeFreeTimePermissions()
        : changeInterviewPermissions();

    const cleanEvents = CalendarEventsUtil.cleanAndOrganiseEvents(
      activePermissions,
      calendarData.events,
      allEvents
    );

    if (Object.values(cleanEvents).flat().length === 0) {
      // no event changes aka calendar is required
      setFieldError(
        'CALENDAR_COMPONENT',
        'Please make at least one edit to continue.'
      );

      return;
    }

    let currentCalendarId = calendarId;
    if (!currentCalendarId) {
      const newCalendar = await CalendarApi.createCalendar({
        candidateId: candidate.id,
        timezone: timezone.iana,
      });
      currentCalendarId = newCalendar.data.id;
    }

    const promises = [];

    // These API calls are in specific order to prevent race-conditions ==================== START
    if (cleanEvents.deleteIds.length > 0) {
      await CalendarApi.deleteCalendarEvents(
        currentCalendarId,
        cleanEvents.deleteIds
      );
    }

    if (cleanEvents.modifiedEvents.length > 0) {
      const payload = cleanEvents.modifiedEvents.reduce(
        (crnt, event) => {
          const { id, ...evnt } = event;
          crnt[id] = evnt;
          return crnt;
        },
        {} as { [key: string]: CalendarEventType }
      );

      promises.push(
        CalendarApi.updateCalendarEvents(currentCalendarId, payload)
      );
    }

    if (cleanEvents.newEvents.length > 0) {
      promises.push(
        CalendarApi.createCalendarEvents(
          currentCalendarId,
          cleanEvents.newEvents
        )
      );
    }

    await Promise.all(promises);
    // These API calls are in specific order to prevent race-conditions ==================== END

    if (cleanEvents.staticEvents.length > 0) {
      setConfirmModalOpen(cleanEvents.staticEvents);
    } else {
      refreshData();
      onClose();
    }
  };

  return confirmModalOpen.length === 0 ? (
    <Form
      name="INTERVIEW_AUTO_FORM"
      schema={CalendarFormSchema}
      initialValues={formInitData}
      onSubmit={handleUpdateOnClick}
    >
      {({ fireSubmit, values, setValues }) => {
        const isAvailabilityMode =
          values.MODE_CHANGER === CalendarScheduleModeOptions[0].id;
        const activePermissions = isAvailabilityMode
          ? changeFreeTimePermissions()
          : changeInterviewPermissions();

        return (
          <Modal size="large" name="INTERVIEW_AUTO_MODAL">
            <ModalHeader name="MODAL_HEADER" onClose={onClose}>
              <Layout position="middle" wrap>
                <LayoutItem rightGutter="16px">
                  <ImageCircle
                    imageName={candidate.calculatedDisplayName}
                    src={CandidateProfileUtil.getProfileImageUri(
                      candidate,
                      PossibleImageSizes.W_100
                    )}
                    size="small"
                    name="TALENT_IMAGE"
                  />
                </LayoutItem>
                <LayoutItem fluid>
                  <SmallHeader name="HEADER_TEXT">
                    {isAvailabilityMode
                      ? `Edit ${candidate.calculatedFirstName}'s interview availability`
                      : `Schedule an interview for ${candidate.calculatedFirstName}`}
                  </SmallHeader>
                </LayoutItem>
                {!!canDoInterviewEvents && (
                  <LayoutItem name="BADGE_CONTAINER" rightGutter="16px">
                    {isAvailabilityMode ? (
                      <Badge name="EDIT_AVAILABILITY" background="green">
                        Edit availability
                      </Badge>
                    ) : (
                      <Badge name="SCHEDULE_INTERVIEW" background="red">
                        Schedule interview
                      </Badge>
                    )}
                  </LayoutItem>
                )}
              </Layout>
            </ModalHeader>
            <ModalSection>
              <Grid>
                {!!canDoInterviewEvents && (
                  <GridRow gutterBottom="24px">
                    <GridColumn>
                      <Dropdown
                        name="MODE_CHANGER"
                        valueKey="id"
                        displayKey="text"
                        options={CalendarScheduleModeOptions}
                        onChange={mode =>
                          onModeChange(
                            mode as CalendarFormType['MODE_CHANGER'],
                            values,
                            setValues as (
                              arg: React.SetStateAction<CalendarFormType>
                            ) => void
                          )
                        }
                        label="Would you like to edit the talents availability or schedule an interview?"
                      />
                    </GridColumn>
                  </GridRow>
                )}
                <GridRow>
                  <GridColumn widescreenWidth={4} smallScreenWidth={12}>
                    {isAvailabilityMode ? (
                      <CondensedMedium name="TALENTS_AVAILABILITY_TEXT">
                        Choose as many time slots as possible to increase the
                        likelihood of being matched for an interview. We suggest
                        a minimum 4 hours over the next two weeks.
                      </CondensedMedium>
                    ) : (
                      <CondensedMedium name="SCHEDULE_TALENT_INTERVIEW_TEXT">
                        Hello! We've highlighted {candidate.calculatedFirstName}
                        's availability in the calendar. Please select one
                        30-minute time slot for
                        {candidate.calculatedFirstName} to interview with{' '}
                        {opportunity.account?.calculatedName}.
                      </CondensedMedium>
                    )}
                    <Gutter bottom="24px" />
                    <FormLabel name="pendo-timezone">Time zone</FormLabel>
                    {talentIana ? (
                      <>
                        <CondensedMedium name="TALENT_TIME_ZONE_TEXT">
                          Times are shown in the talent's local time zone.
                        </CondensedMedium>
                        <CondensedMedium name="TALENT_TIME_ZONE">
                          {CalendarTimeZoneUtil.getDisplayZone(timezone)}
                        </CondensedMedium>
                      </>
                    ) : (
                      <CondensedMedium name="NO_TALENT_TIME_ZONE">
                        A default time zone has not been set. Please reach out
                        to the talent to confirm and adjust their preferred time
                        zone.
                      </CondensedMedium>
                    )}
                    <Gutter bottom="16px" />
                  </GridColumn>
                  <GridColumn widescreenWidth={8} smallScreenWidth={12}>
                    <Calendar
                      name="CALENDAR_COMPONENT"
                      backgroundEvents={values.BACKGROUND_EVENTS}
                      permissions={activePermissions}
                      dayMode={DayWeekMode}
                      weekMode={DayWeekMode}
                      timezone={timezone.iana}
                    />
                    <Gutter bottom="8px" />
                    <Layout position="left middle" horizontalGutter="8px">
                      <LayoutItem>
                        <LegendKey type="availability" />
                      </LayoutItem>
                      <LayoutItem rightGutter="16px">
                        <Paragraph name="LEGEND_TEXT_AVAILABILITY">
                          {candidate.calculatedFirstName}'s availability
                        </Paragraph>
                      </LayoutItem>
                      <LayoutItem>
                        <LegendKey type="scheduled" />
                      </LayoutItem>
                      <LayoutItem>
                        <Paragraph name="LEGEND_TEXT">
                          Scheduled Interviews
                        </Paragraph>
                      </LayoutItem>
                    </Layout>
                  </GridColumn>
                </GridRow>
              </Grid>
            </ModalSection>
            <ModalFooter>
              <Button
                onClick={onClose}
                pattern="primary"
                variation="outline"
                name="CANCEL_BUTTON"
              >
                Cancel
              </Button>
              {!isMobile &&
                (isAvailabilityMode &&
                !values.CALENDAR_COMPONENT?.find(
                  event =>
                    !CalendarEventsUtil.isInThePast(
                      event.endTime,
                      activePermissions
                    ) &&
                    event.busy &&
                    event.state !== EventStates.SAVED
                ) ? (
                  <Button onClick={fireSubmit} name="SAVE_BUTTON">
                    Save
                  </Button>
                ) : (
                  <Button onClick={fireSubmit} name="NEXT_BUTTON">
                    Next
                  </Button>
                ))}
            </ModalFooter>
          </Modal>
        );
      }}
    </Form>
  ) : (
    <TalentInterviewAvailabilityModalConfirm
      refreshData={refreshData}
      onBackHandler={() => {
        setConfirmModalOpen([]);
      }}
      opportunity={opportunity}
      candidate={candidate}
      onClose={onClose}
      scheduledEvents={confirmModalOpen.sort((a, b) => {
        return a.startTime > b.startTime ? 1 : -1;
      })}
      timezone={timezone}
    />
  );
};
