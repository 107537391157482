import { z } from 'zod';
import { CalendarEventSchema } from '@axiom/validation';
import { ZodArrayToEnum } from '@axiom/types';

import { CalendarScheduleModes } from './talent-interview-availability-modal-const';

const optionsEnum = Object.values(CalendarScheduleModes);

export const CalendarFormSchema = z
  .object({
    CALENDAR_COMPONENT: CalendarEventSchema.array(),
    BACKGROUND_EVENTS: CalendarEventSchema.array(),
    MODE_CHANGER: z.enum(ZodArrayToEnum(optionsEnum)),
  })
  .superRefine((values, ctx) => {
    const hasEventErrors = [
      ...values.CALENDAR_COMPONENT,
      ...values.BACKGROUND_EVENTS,
    ].some(item => !!item.error);

    if (hasEventErrors) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['CALENDAR_COMPONENT'],
        message: 'Please select at least one available time to continue.',
      });
    }
  });

export type CalendarFormType = z.infer<typeof CalendarFormSchema>;
