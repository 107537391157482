import React from 'react';
import {
  Button,
  Flashy,
  Gutter,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  ParagraphHeader,
} from '@axiom/ui';
import { TopTask, TopTaskTypes } from '@axiom/validation';

export type WorkspaceTopTasksCandidateIssueModalProps = {
  topTask: TopTask;
  onClose: () => void;
};

export const WorkspaceTopTasksCandidateIssueModal = ({
  topTask,
  onClose,
}: WorkspaceTopTasksCandidateIssueModalProps) => {
  return (
    <Modal name="TOP_TASK_CANDIDATE_ISSUE_MODAL" size="confirm">
      <ModalHeader
        name="TOP_TASK_CANDIDATE_ISSUE_MODAL_HEADER"
        onClose={() => onClose()}
      >
        Update Data in Greenhouse
      </ModalHeader>
      <ModalSection>
        <Gutter bottom="16px">
          <Paragraph>
            Missing information in Greenhouse is preventing this Talent from
            being priced and submitted.
          </Paragraph>
        </Gutter>
        <Gutter bottom="16px">
          <ParagraphHeader>Talent</ParagraphHeader>
          <Paragraph name="TOP_TASK_CANDIDATE_ISSUE_CANDIDATE_DISPLAY_NAME">
            {topTask.candidateCalculatedDisplayName}
          </Paragraph>
        </Gutter>
        <ParagraphHeader>Greenhouse Field(s)</ParagraphHeader>
        <Paragraph name="TOP_TASK_CANDIDATE_ISSUE_FIELD_NAME">
          {(topTask.topTaskType ===
            TopTaskTypes.CANDIDATE_ISSUE_MISSING_ADDRESS_FIELDS &&
            'State/Province/County') ||
            (topTask.topTaskType ===
              TopTaskTypes.CANDIDATE_ISSUE_MISSING_FOCUS_AREA &&
              'LT - Practice Area / Focus') ||
            (topTask.topTaskType ===
              TopTaskTypes.CANDIDATE_ISSUE_MISSING_OCCUPATION_TYPE &&
              'LT - Job Profile') ||
            'N/A'}
        </Paragraph>
        {topTask.topTaskType ===
          TopTaskTypes.CANDIDATE_ISSUE_MISSING_ADDRESS_FIELDS && (
          <Paragraph name="TOP_TASK_CANDIDATE_ISSUE_FIELD_NAME_ADDITIONAL">
            Country
          </Paragraph>
        )}
        {topTask.topTaskType ===
          TopTaskTypes.CANDIDATE_ISSUE_MISSING_FOCUS_AREA && (
          <Gutter name="TOP_TASK_CANDIDATE_ISSUE_FIELD_NAME_NOTE" top="16px">
            <Paragraph>
              <Flashy italic>
                Note: talent must be assigned to a Focus Area, not a Practice
                Area in this field
              </Flashy>
            </Paragraph>
          </Gutter>
        )}
      </ModalSection>
      <ModalFooter>
        <Button
          name="TOP_TASK_CANDIDATE_ISSUE_MODAL_CLOSE_BUTTON"
          onClick={() => onClose()}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};
