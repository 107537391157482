import {
  USERS_LOAD,
  USERS_LOAD_ERROR,
  USERS_LOADED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  USER_GROUPS_LOAD,
  USER_GROUPS_LOADED,
  USER_GROUPS_ERROR,
  SET_USER_GROUPS,
  SET_USER_GROUPS_ERROR,
  SET_USER_GROUPS_SUCCESS,
} from '../constants';

export const loadUsers = payload => ({
  type: USERS_LOAD,
  payload,
});

export const usersLoaded = payload => ({
  type: USERS_LOADED,
  payload,
});

export const usersLoadedError = payload => ({
  type: USERS_LOAD_ERROR,
  payload,
});

export const updateUserSuccess = payload => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserError = payload => ({
  type: UPDATE_USER_ERROR,
  payload,
});

export const loadUserGroups = payload => ({
  type: USER_GROUPS_LOAD,
  payload,
});

export const userGroupsLoaded = payload => ({
  type: USER_GROUPS_LOADED,
  payload,
});

export const userGroupsError = payload => ({
  type: USER_GROUPS_ERROR,
  payload,
});

export const setUserGroups = payload => ({
  type: SET_USER_GROUPS,
  payload,
});

export const setUserGroupsError = payload => ({
  type: SET_USER_GROUPS_ERROR,
  payload,
});

export const setUserGroupsSuccess = payload => ({
  type: SET_USER_GROUPS_SUCCESS,
  payload,
});
