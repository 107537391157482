/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import { oneOf, shape } from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { IconButton } from '@axiom/ui';

import { FormCell, FormRow } from '../../styled/form';
import {
  TALENT_FIELD_LANGUAGES,
  talentFieldLabels,
} from '../../models/talent-fields-const';
import { formatDataTestId } from '../../utils/data-test-util';
import { PreloadedLanguagesStore } from '../../stores/preloaded-languages-store';
import DropdownList from '../DropdownList/DropdownList';

import {
  languageCEFRItems,
  languageProficiencyItems,
  languageSkillItems,
} from './talent-profile-edit-form-languages-const';
import {
  LanguageDescription,
  LanguageForm,
  LanguageItem,
  LanguageList,
  LanguageDisplayItem,
} from './TalentProfileEditFormLanguagesStyles';

const INITIAL_DROPDOWN_STATE = {
  language: null,
  proficiency: null,
  skill: null,
  cefr: null,
};

const typeMap = {
  opportunity: {
    proficiency: 'requiredLanguageProficiency',
    skill: 'requiredLanguageSkill',
  },
  talent: {
    cefr: 'languageCEFR',
    proficiency: 'languageProficiency',
    skill: 'languageSkill',
  },
};

class TalentProfileEditFormLanguages extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_DROPDOWN_STATE;
  }

  handleLanguageChange = value => {
    this.setState({ language: value });
  };

  handleProficiencyChange = value => {
    this.setState({ proficiency: value });
  };

  handleSkillChange = value => {
    this.setState({ skill: value }, () => {
      if (!typeMap[this.props.type].cefr) this.handleAddLanguage();
    });
  };

  handleCEFRChange = value => {
    this.setState({ cefr: value }, this.handleAddLanguage);
  };

  handleAddLanguage = () => {
    const { push } = this.props.fields;
    const { language, proficiency, skill, cefr } = this.state;

    const fields = typeMap[this.props.type];
    const values = {
      id: language,
      [fields.proficiency]: proficiency,
      [fields.skill]: skill,
    };

    if (fields.cefr) {
      values[fields.cefr] = cefr;
    }

    push(values);

    this.setState(INITIAL_DROPDOWN_STATE);
  };

  handleRemoveLanguage = index => {
    this.props.fields.remove(index);
  };

  render() {
    const {
      handleCEFRChange,
      handleLanguageChange,
      handleProficiencyChange,
      handleSkillChange,
      handleAddLanguage,
      handleRemoveLanguage,
      props: { languages, type, fields },
      state: { language, proficiency, skill, cefr },
    } = this;

    return (
      <LanguageForm>
        <FormRow>
          <DropdownList
            name="language"
            onChange={handleLanguageChange}
            options={languages}
            label={`${talentFieldLabels.language}:`}
            value={language}
          />
          <DropdownList
            name="proficiency"
            onChange={handleProficiencyChange}
            options={languageProficiencyItems}
            label={`${talentFieldLabels.languageProficiency}:`}
            value={proficiency}
            disabled={!language}
          />
          <DropdownList
            name="skill"
            onChange={handleSkillChange}
            options={languageSkillItems}
            label={`${talentFieldLabels.languageSkill}:`}
            value={skill}
            disabled={!proficiency}
          />
          <FormCell>
            {!!typeMap[type].cefr && (
              <DropdownList
                name="cefr"
                onChange={handleCEFRChange}
                options={languageCEFRItems}
                label={`${talentFieldLabels.languageCEFR}:`}
                value={cefr}
                disabled={!skill}
              />
            )}
            <IconButton
              variation="minimal"
              pattern="secondary"
              icon="plus"
              className="plus-icon"
              disabled={!proficiency}
              onClick={handleAddLanguage}
              name="ADDLANGUAGEBUTTON"
            />
          </FormCell>
        </FormRow>
        {fields.length > 0 && (
          <LanguageList data-test={formatDataTestId('selected_languages')}>
            {fields.map((name, index) => (
              <LanguageItem
                key={`${name}_${index.toString()}`}
                data-test={formatDataTestId('selected_language')}
              >
                <LanguageDescription>
                  <Field
                    name={`${name}.id`}
                    component={({ input: { value } }) =>
                      value && (
                        <LanguageDisplayItem>
                          {languages.find(lang => lang.value === value).label};
                        </LanguageDisplayItem>
                      )
                    }
                  />
                  <Field
                    name={`${name}.${typeMap[type].proficiency}`}
                    component={({ input: { value } }) =>
                      value && (
                        <LanguageDisplayItem>
                          {
                            languageProficiencyItems.find(
                              prof => prof.value === value
                            ).label
                          }
                          ;
                        </LanguageDisplayItem>
                      )
                    }
                  />
                  <Field
                    name={`${name}.${typeMap[type].skill}`}
                    component={({ input: { value } }) =>
                      value && (
                        <LanguageDisplayItem>
                          {
                            languageSkillItems.find(s => s.value === value)
                              .label
                          }
                          ;
                        </LanguageDisplayItem>
                      )
                    }
                  />
                  <Field
                    name={`${name}.${typeMap[type].cefr}`}
                    component={({ input: { value } }) =>
                      value && (
                        <LanguageDisplayItem>
                          {
                            languageCEFRItems.find(exam => exam.value === value)
                              .label
                          }
                          ;
                        </LanguageDisplayItem>
                      )
                    }
                  />
                </LanguageDescription>
                <IconButton
                  variation="minimal"
                  pattern="secondary"
                  icon="trash"
                  onClick={() => handleRemoveLanguage(index)}
                  name="REMOVE_LANGUAGE_BUTTON"
                />
              </LanguageItem>
            ))}
          </LanguageList>
        )}
      </LanguageForm>
    );
  }
}

TalentProfileEditFormLanguages.propTypes = {
  fields: shape({}).isRequired,
  languages: PreloadedLanguagesStore.getDataShape().isRequired,
  type: oneOf(['opportunity', 'talent']).isRequired,
};

const mapStateToPops = state => ({
  languages: PreloadedLanguagesStore.selectData(state),
});

export default connect(mapStateToPops)(props => (
  <FieldArray
    name={TALENT_FIELD_LANGUAGES}
    component={TalentProfileEditFormLanguages}
    initialValue={props.talent.languages}
    {...props}
  />
));
