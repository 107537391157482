import React, { FC, useEffect, useState } from 'react';
import {
  Candidate,
  Opportunity,
  OpportunityCandidate,
  Position,
} from '@axiom/validation';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardSection,
  CondensedMedium,
  Flashy,
  Grid,
  GridColumn,
  GridRow,
  Layout,
  SmallHeader,
  ToastUtil,
  CondensedHeader,
  CondensedLarge,
  Gutter,
  LayoutItem,
} from '@axiom/ui';

import {
  FormSchema,
  GetMoreMatchesModal,
} from '../GetMoreMatchesModal/GetMoreMatchesModal';
import { OpportunityCandidateMlRecommendationApi } from '../../api/opportunity-candidate-ml-recommendations-api';
import { OpportunityStore } from '../../stores/opportunity-store';
import { AiDuplicatesMessage } from '../AiDuplicatesMessage/AiDuplicatesMessage';
import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';
import { AddTalentUtil } from '../../utils/AddTalentUtil';

import { OpportunityTalentParentPositionEmpty } from './OpportunityTalentParentPositionEmpty';
import { noMatchesErrorText } from './OpportunityTalentAiMatchControl';
import { OpportunityTalentParentPositionList } from './OpportunityTalentParentPositionList';
import { OTScrollable } from './OpportunityTalentParentPositionStyles';
import { OpportunityTalentFindCandidateModal } from './OpportunityTalentFindCandidateModal';

interface OpportunityTalentParentPositionProps {
  name: string;
  title?: string;
  opportunity: Opportunity;
  candidates: Array<OpportunityCandidate>;
  isFilled?: boolean;
  isTalentPool?: boolean;
  positionId?: Position['id'];
  positions?: Array<Position>;
}

export const OpportunityTalentParentPosition: FC<
  OpportunityTalentParentPositionProps
> = ({
  name,
  title,
  opportunity,
  candidates,
  isFilled,
  isTalentPool,
  positionId,
  positions,
}) => {
  const [hasGottenNoRecommendations, setHasGottenNoRecommendations] =
    useState(false);
  const [showFindCandidateModal, setShowFindCandidateModal] = useState(false);
  const [showGetMoreMatchesModal, setShowGetMoreMatchesModal] = useState(false);
  const [shouldTriggerModal, setShouldTriggerModal] = useState(
    opportunity.hasBeenAIMatched || hasGottenNoRecommendations
  );
  const [savedFilters, setSavedFilters] = useState<FormSchema>({});
  const [newlyAddedCandidateId, setNewlyAddedCandidateId] = useState(null);

  useEffect(() => {
    if (newlyAddedCandidateId) {
      AddTalentUtil.handleWarnings(candidates, newlyAddedCandidateId);
    }
  }, [newlyAddedCandidateId]);

  const onGetAiMatchesClick = async () => {
    if (shouldTriggerModal) {
      setShowGetMoreMatchesModal(true);
    } else {
      // we're sending the default parameters (first button click)
      const response =
        await OpportunityCandidateMlRecommendationApi.createOpportunityCandidateMlRecommendations(
          { opportunityId: opportunity.id },
          {}
        );
      await OpportunityLegacyApi.refreshOpportunity(opportunity.id);
      setShouldTriggerModal(true);
      // response is null if there is a known server error
      if (response !== null) {
        await OpportunityStore.load(opportunity.id);
        const { data: { candidateIds, opportunityCandidates } = {} } = response;
        if (!candidateIds?.length) {
          ToastUtil.add({
            dismissible: true,
            name: `NOMATCHES`,
            type: 'error',
            children: (
              <>
                <CondensedHeader>Error</CondensedHeader>
                <CondensedLarge>{noMatchesErrorText}</CondensedLarge>
              </>
            ),
          });
          setHasGottenNoRecommendations(true);
          setShouldTriggerModal(false);
        }

        // Pop a toast if talent was already on the opp before the AI match
        const dupes = opportunityCandidates?.filter(
          ({ duplicate }) => duplicate
        );
        if (dupes?.length) {
          ToastUtil.add({
            dismissible: true,
            name: `DUPLICATECAND`,
            type: 'critical',
            children: <AiDuplicatesMessage duplicates={dupes} />,
          });
        }
      }
    }
  };

  return (
    <Card name={name}>
      <CardHeader>
        <Grid>
          <GridRow>
            <GridColumn>
              <Layout
                direction="horizontal"
                horizontalGutter="8px"
                position="middle"
              >
                <LayoutItem>
                  <Badge background="gray" name="candidate-count-badge">
                    {candidates.length}
                  </Badge>
                </LayoutItem>
                <LayoutItem fluid>
                  <SmallHeader maxLines={1} name={`${name}-card-title`}>
                    {title}
                  </SmallHeader>
                </LayoutItem>
                {(!!opportunity.isFulfillmentActive &&
                  !!isTalentPool &&
                  !!opportunity.isAiMatchable && (
                    <LayoutItem>
                      <Button
                        name="GET_AI_MATCHES_BUTTON"
                        icon="add-user"
                        iconPosition="right"
                        onClick={onGetAiMatchesClick}
                      >
                        {opportunity.hasBeenAIMatched
                          ? 'Get More Matches'
                          : 'Get AI Matches'}
                      </Button>
                    </LayoutItem>
                  )) ||
                  (!!isFilled && (
                    <LayoutItem>
                      <Badge background="green" name="filled-badge">
                        Filled
                      </Badge>
                    </LayoutItem>
                  ))}
              </Layout>
            </GridColumn>
          </GridRow>
        </Grid>
      </CardHeader>
      <OTScrollable tall={isTalentPool}>
        <CardSection>
          {!!opportunity.aiUnmatchableMessage &&
            !!isTalentPool &&
            !opportunity.isAiMatchable && (
              <Gutter bottom="16px">
                <CondensedMedium name="AI_UNMATCHABLE_MESSAGE">
                  <Flashy bold>Note:</Flashy> {opportunity.aiUnmatchableMessage}
                </CondensedMedium>
              </Gutter>
            )}
          {!!opportunity.isFulfillmentActive && (
            <Gutter bottom="16px">
              <Layout direction="vertical">
                <Button
                  name="ADD_TALENT_BUTTON"
                  variation="outline"
                  pattern="secondary"
                  onClick={() => setShowFindCandidateModal(true)}
                >
                  Add Talent
                </Button>
              </Layout>
            </Gutter>
          )}
          {candidates.length === 0 ? (
            <OpportunityTalentParentPositionEmpty isTalentPool={isTalentPool} />
          ) : (
            <OpportunityTalentParentPositionList
              opportunity={opportunity}
              candidates={candidates}
              isTalentPool={isTalentPool}
            />
          )}
        </CardSection>
      </OTScrollable>
      {!!showFindCandidateModal && (
        <OpportunityTalentFindCandidateModal
          onClose={(candidateId: Candidate['id']) => {
            setShowFindCandidateModal(false);
            setNewlyAddedCandidateId(candidateId);
          }}
          opportunityId={opportunity.id}
          positionId={positionId}
          positions={positions}
        />
      )}
      {!!showGetMoreMatchesModal && (
        <GetMoreMatchesModal
          opportunity={opportunity}
          onRequestSuccess={({ filters }) => {
            setSavedFilters(filters);
          }}
          onClose={() => setShowGetMoreMatchesModal(false)}
          savedFilters={savedFilters}
        />
      )}
    </Card>
  );
};
