import React from 'react';
import {
  Button,
  Form,
  Gutter,
  Input,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
} from '@axiom/ui';
import {
  EnvoyCandidateSearchFiltersFormSchemaFields,
  EnvoyCandidateSearchFiltersSaveForm,
  EnvoyCandidateSearchFiltersSaveFormSchema,
  EnvoyCandidateSearchFiltersForm as FiltersFormSchema,
} from '@axiom/validation';

export const CandidateFiltersSaveFormModal = ({
  filters,
  handleSaveFilters,
  onClose,
}: {
  filters: FiltersFormSchema;
  handleSaveFilters: (
    saveFilters: EnvoyCandidateSearchFiltersSaveForm & {
      filters: FiltersFormSchema;
    }
  ) => void;
  onClose: () => void;
}) => {
  return (
    <Form
      name="SAVE_CANDIDATE_FILTERS_FORM"
      schema={EnvoyCandidateSearchFiltersSaveFormSchema}
      onSubmit={(formData: EnvoyCandidateSearchFiltersSaveForm) => {
        const savedFilters = Object.fromEntries(
          Object.entries(filters).filter(
            ([_, v]) =>
              v !== undefined &&
              v !== null &&
              !(typeof v === 'object' && Object.keys(v).length === 0)
          )
        );

        Object.keys(EnvoyCandidateSearchFiltersFormSchemaFields)
          .filter(k => !Object.keys(savedFilters).includes(k))
          .forEach(key => {
            savedFilters[key] = null;
          });

        handleSaveFilters({
          ...formData,
          filters: savedFilters,
        });
        onClose();
      }}
    >
      {({ fireSubmit }) => (
        <Modal>
          <ModalHeader onClose={() => onClose()}>Saved Search</ModalHeader>
          <ModalSection>
            <Gutter bottom="16px">
              Enter a search name as you want it to appear on the search Saved
              tab.
            </Gutter>
            <Input name="name" label="Search Name" />
          </ModalSection>
          <ModalFooter>
            <Button onClick={() => onClose()} variation="outline">
              Cancel
            </Button>
            <Button onClick={fireSubmit}>Save</Button>
          </ModalFooter>
        </Modal>
      )}
    </Form>
  );
};
