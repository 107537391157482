import { Api } from '@axiom/ui';
import { z } from 'zod';
import { Candidate, CandidateProfileStatuses } from '@axiom/validation';
import { ZodArrayToEnum } from '@axiom/types';

import { EnvUtil } from '../utils/env-util';
import LegacyApiHelper from '../classes/legacy-api-helper';

/**
 * This is displayed to user so needs to be talent, not candidate
 */
const api = new LegacyApiHelper('user talent');

export const UserBenchTalentTypes = {
  owner: 'Owned By Me',
  collaborator: 'Collaborating On',
} as const;

const UserBenchTalentTypeValues = Object.keys(UserBenchTalentTypes) as Array<
  keyof typeof UserBenchTalentTypes
>;

export const UserBenchTalentSortKeys = {
  lastName: 'lastName',
  profileStatus: 'profileStatus',
  employmentStatus: 'employmentStatus',
  practiceAreaId: 'practiceAreaId',
  weeklyAvailability: 'weeklyAvailability',
  availabilityPreferencesUpdatedAt: 'availabilityPreferencesUpdatedAt',
  mobilePhone: 'mobilePhone',
  personalEmail: 'personalEmail',
  furthestCandidacyStatus: 'furthestCandidacyStatus',
  soonestEngagementAccountName: 'soonestEngagementAccountName',
  alphabeticallySmallestEngagementAccountName:
    'alphabeticallySmallestEngagementAccountName',
  soonestEngagementEndDate: 'soonestEngagementEndDate',
} as const;

export const UserBenchTalentSort = {
  [UserBenchTalentSortKeys.lastName]: 'Talent',
  [UserBenchTalentSortKeys.profileStatus]: 'Profile status',
  [UserBenchTalentSortKeys.employmentStatus]: 'Employment status',
  [UserBenchTalentSortKeys.practiceAreaId]: 'Practice area',
  [UserBenchTalentSortKeys.weeklyAvailability]: 'Current availability',
  [UserBenchTalentSortKeys.availabilityPreferencesUpdatedAt]:
    'Availability last confirmed',
  [UserBenchTalentSortKeys.mobilePhone]: 'Mobile phone',
  [UserBenchTalentSortKeys.personalEmail]: 'Personal email',
  [UserBenchTalentSortKeys.furthestCandidacyStatus]: 'Furthest candidate',
  [UserBenchTalentSortKeys.alphabeticallySmallestEngagementAccountName]:
    'Account name',
  [UserBenchTalentSortKeys.soonestEngagementEndDate]: 'Roll off date',
} as const;

const UserBenchTalentSortValues = Object.keys(UserBenchTalentSort) as Array<
  keyof typeof UserBenchTalentSort
>;

export const UserBenchFilters = z.object({
  profileStatus: z
    .array(z.enum(ZodArrayToEnum(CandidateProfileStatuses)))
    .nullish(),
  type: z.enum(ZodArrayToEnum(UserBenchTalentTypeValues)).nullish(),
  sort: z.enum(ZodArrayToEnum(UserBenchTalentSortValues)).nullish(),
});

export type UserBenchFilterType = z.infer<typeof UserBenchFilters>;

export const getTalentByUserIdWithFilters = async (
  search: Record<string, unknown>
) => {
  const { ownerUserId, type, sort, filter, page } = search;
  const { data, _meta } = await api.GET(`/users/${ownerUserId}/candidates`, {
    type,
    sort,
    filter,
    page,
  });
  return {
    data,
    id: ownerUserId,
    meta: _meta,
  };
};

export type readUserTalentResponseType = {
  data: Array<Candidate>;
  _meta: { resultCount: number; currentPage: number; pageCount: number };
};

class UserTalentApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
    });
  }

  readUserTalent(
    ownerUserId: string,
    page: number,
    { profileStatus, sort, type }: UserBenchFilterType,
    order?: string
  ) {
    return super.read<readUserTalentResponseType>({
      endpoint: `/users/${ownerUserId}/candidates`,
      method: 'GET',
      encodeQuery: false,
      query: {
        filters: { profileStatus },
        page,
        sort,
        order: order || undefined,
        type,
      },
    });
  }

  refreshUserTalent(ownerUserId: string) {
    return super.invalidate(`/users/${ownerUserId}/candidates`);
  }
}

export const UserTalentApi = new UserTalentApiClass();
