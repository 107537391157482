// import { Candidate } from '@axiom/const'; // can't import from @axiom/const here

import { GenericDto } from './generic-dto';

const Candidate = {
  AVAILABILITY_PREFERENCES_UPDATED_AT: 'availabilityPreferencesUpdatedAt',
};

export const CandidateDto = class CandidateDto extends GenericDto {
  constructor(body) {
    super(body, [
      'address1',
      'address2',
      'addressCity',
      'addressCountry',
      'addressCountryCode',
      'addressState',
      'addressZip',
      'availabilityNotes',
      'axiomEmployeeType',
      'barredLocations',
      'candidateHeader',
      'candidateSummary',
      'candidateSummaryApprovedBy',
      'compensation',
      'counselWorkPreference',
      'daysDesiredRemote',
      'daysRequiredRemote',
      'daysWillingToCommute',
      'desiredCommute',
      'desiredEngagementSpecificCompensation',
      'desiredWeeklyMaxHours',
      'displayFirstName',
      'displayLastName',
      'employeeType',
      'employmentStatus',
      'firstName',
      'homeOfficeId',
      'homePhone',
      'hourlyCompensation',
      'hourlyPriceVariable',
      'hourlyPrice10HrReserved',
      'hourlyPrice20HrReserved',
      'hourlyPrice30HrReserved',
      'hourlyPrice40HrReserved',
      'weeklyPriceFullTime',
      'hourlyPriceVariableLawFirm',
      'hourlyPrice10HrReservedLawFirm',
      'hourlyPrice20HrReservedLawFirm',
      'hourlyPrice30HrReservedLawFirm',
      'hourlyPrice40HrReservedLawFirm',
      'weeklyPriceFullTimeLawFirm',
      'pricingCurrency',
      'integrationId',
      'isFlagged',
      'isOpenToAdHoc',
      'isOpenToCommute',
      'isOpenToFullTime',
      'isOpenToMultipleClients',
      'isOpenToOtherWork',
      'isOpenToPartTime',
      'isOpenToRemote',
      'isProfileShared',
      'isSpotlightedLawyer',
      'searchableByClient',
      'lastName',
      'lawSchool',
      'maxMinutesCommuteOneWay',
      'middleName',
      'mobilePhone',
      'noticePeriod',
      'occupationType',
      'openToAdviceAndCounsel',
      'openToSecondment',
      'workStylePreferencesReviewedAt',
      'workStylePreferencesReviewedBy',
      'programsPreferencesReviewedAt',
      'programsPreferencesReviewedBy',
      'profileVisibilityReviewedAt',
      'profileVisibilityPreferencesReviewedBy',
      'openToVariableHourlyProjects',
      'openToLargeProjects',
      'openToPermanentPlacement',
      'ownerUserId',
      'partTimeHoursFri',
      'partTimeHoursMon',
      'partTimeHoursThu',
      'partTimeHoursTue',
      'partTimeHoursWed',
      'personalEmail',
      'practiceAreaId',
      'practiceStartYear',
      'preferredContactMethod',
      'profileStatus',
      'resume',
      'terminationDate',
      'terminationReason',
      'workEmail',
      Candidate.AVAILABILITY_PREFERENCES_UPDATED_AT,
    ]);
  }
};
