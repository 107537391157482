import { EnvUtil } from './env-util';

export const generateBenchUrl = accountId =>
  `${EnvUtil.clientUrl}/bench?account=${accountId}`;
export const generateAFCProjectsUrl = accountId =>
  `${EnvUtil.clientUrl}/my-axiom/account/${accountId}/engagements`;
export const generateAFCProjectsMatchesUrl = (accountId, submissionId) =>
  `${EnvUtil.clientUrl}/my-axiom/account/${accountId}/submission/${submissionId}/matches`;
export const generateAFCLandingPageUrl = accountId =>
  `${EnvUtil.clientUrl}/my-axiom/account/${accountId}`;
