import fGet from 'lodash/fp/get';
import { getFormValues, hasSubmitSucceeded } from 'redux-form';
import { createSelector } from 'reselect';

import { OPPORTUNITY_EDIT } from '../constants';
import { COLLABORATORS } from '../../components/OpportunityEditForm/opportunity-edit-form-const';
import {
  OPPORTUNITY_FIELD_STAGE,
  OPPORTUNITY_FIELD_OWNER_USER_ID,
} from '../../models/opportunity-fields-const';
import {
  EDITABLE_OPPORTUNITY_STATUS_BLACKLIST,
  JOBS_FORM as OPPORTUNITIES_FORM,
} from '../../api/constants';
import { OpportunityEngagementsStore } from '../../stores/opportunity-engagements-store';
import { OpportunityPositionsStore } from '../../stores/opportunity-positions-store';
import { OpportunityStore } from '../../stores/opportunity-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { StateComponentUtil } from '../../utils/state-component-util';
import { EventsStore } from '../../stores/events-store';
import { EditOpportunityTalentTabStore } from '../../stores/edit-opportunity-talent-tab-store';

import { fullNameByIdSelector } from './users';
import { makeOpportunitiesByIdMap } from './opportunities';

export const makeOpportunitiesViewSelector = () =>
  createSelector(
    makeOpportunitiesByIdMap(),
    fGet('opportunityCandidates.byOpportunityId'),
    fGet('talent.byId'),
    fGet('opportunityCollaborators.byOpportunityId'),
    fGet('opportunityLanguages.byOpportunityId'),
    getFormValues(OPPORTUNITIES_FORM),
    hasSubmitSucceeded(OPPORTUNITY_EDIT),
    fullNameByIdSelector,
    state => PreloadedUserStore.select(state),
    state => OpportunityStore.select(state),
    state => OpportunityEngagementsStore.select(state),
    state => OpportunityPositionsStore.select(state),
    state => EventsStore.select(state),
    state => EditOpportunityTalentTabStore.select(state),
    (
      opportunitiesById,
      candidates = {},
      talentById,
      collaborators,
      languagesById,
      formValues = {},
      hasSubmitted,
      fullNameById,
      user,
      opportunityState,
      opportunityEngagementsState,
      opportunityPositionsState,
      eventsState,
      editOpportunityTalentTabState
    ) => {
      /**
       * Hack until we can get rid of this selector. Because child components within OpportunityDetails are
       * expecting this data on the opp, we need to do this until each of these data points can be requested
       * from the child components themselves, then we can get rid of this reselect selector.
       */
      const opportunityData = StateComponentUtil.hasLoaded([opportunityState])
        ? opportunityState.data
        : null;

      const opportunity = opportunityData
        ? {
            ...opportunityData,
            candidates: candidates[opportunityData.id] || [],
            [COLLABORATORS]: (collaborators[opportunityData.id] || []).map(
              ({ id }) => id
            ),
            languages: (languagesById[opportunityData.id] || []).map(
              language => ({
                id: language.id,
                requiredLanguageProficiency:
                  language.requiredLanguageProficiency,
                requiredLanguageSkill: language.requiredLanguageSkill,
              })
            ),
            ownerUserFullName:
              fullNameById[opportunityData[OPPORTUNITY_FIELD_OWNER_USER_ID]],
            editable: !EDITABLE_OPPORTUNITY_STATUS_BLACKLIST.has(
              opportunityData[OPPORTUNITY_FIELD_STAGE]
            ),
          }
        : null;

      return {
        hasSubmitted,
        opportunity,
        searchTerm: formValues.search,
        user,
        opportunityState,
        opportunityEngagementsState,
        opportunityPositionsState,
        eventsState,
        editOpportunityTalentTabState,
      };
    }
  );

export default makeOpportunitiesViewSelector;
