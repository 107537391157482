import React from 'react';
import {
  ToastUtil,
  CondensedHeader,
  CondensedLarge,
  ApiError,
  ForbiddenError,
  UnauthorizedError,
} from '@axiom/ui';
import {
  Candidate,
  Opportunity,
  OpportunityCandidate,
  Position,
} from '@axiom/validation';

import { OpportunityLegacyApi } from '../api/opportunities-legacy-api';
import { CandidateOpportunityLegacyApi } from '../api/candidate-opportunities-legacy-api';

export const AddTalentUtil = {
  addApiErrorToast: (e: ApiError) => {
    if (Array.isArray(e.getErrors())) {
      (e.getErrors() as []).forEach((err, index) => {
        ToastUtil.add({
          dismissible: true,
          name: `ADD_TALENT_ERROR_${index + 1}`,
          type: 'error',
          children: (
            <>
              <CondensedHeader>Error</CondensedHeader>
              <CondensedLarge>{err}</CondensedLarge>
            </>
          ),
        });
      });
    } else {
      ToastUtil.add({
        dismissible: true,
        name: `ADD_TALENT_ERROR`,
        type: 'error',
        children: (
          <>
            <CondensedHeader>Error</CondensedHeader>
            <CondensedLarge>
              {(e.getErrors() as { message: string })?.message ??
                (typeof e.getErrors() === 'string'
                  ? (e.getErrors() as string)
                  : 'An unknown issue occurred')}
            </CondensedLarge>
          </>
        ),
      });
    }
  },

  handleSubmit: async (
    opportunityId: Opportunity['id'],
    candidateId: Candidate['id'],
    positionId: Position['id']
  ) => {
    try {
      await OpportunityLegacyApi.createOpportunityCandidates(opportunityId, {
        candidateIds: [candidateId],
        positionId,
      });

      if (positionId) {
        await OpportunityLegacyApi.updateBurdendedCost(
          opportunityId,
          candidateId
        );
      }
      await OpportunityLegacyApi.refreshOpportunityCandidates(opportunityId);
      await OpportunityLegacyApi.refreshOpportunityPositions(opportunityId);
      await CandidateOpportunityLegacyApi.refreshCandidateOpportunities(
        candidateId
      );
    } catch (e) {
      if (e instanceof ForbiddenError || e instanceof UnauthorizedError) {
        throw e;
      } else {
        AddTalentUtil.addApiErrorToast(e as ApiError);
      }
    }
  },

  handleWarnings: (
    opportunityCandidates: OpportunityCandidate[],
    candidateId: Candidate['id']
  ) => {
    const opportunityCandidate = opportunityCandidates?.find(
      oc => oc.candidateId === candidateId
    );

    if (opportunityCandidate?.meetsMinPayRequirements === false) {
      ToastUtil.add({
        dismissible: true,
        name: 'MIN_PAY_REQUIREMENT_WARNING',
        type: 'critical',
        children: (
          <>
            <CondensedHeader>Warning</CondensedHeader>
            <CondensedLarge>
              This talent has a guaranteed pay requirement and the position you
              are adding them to does not meet the minimum reserved hours.
            </CondensedLarge>
          </>
        ),
      });
    }

    if (opportunityCandidate?.consultant1099UtilizationWarning === true) {
      ToastUtil.add({
        dismissible: true,
        name: `CONSULTATION_UTILIZATION_WARNING`,
        type: 'critical',
        children: (
          <>
            <CondensedHeader>Warning</CondensedHeader>
            <CondensedLarge>
              Engagement of Independent Contractors for more than 30+ hours is
              not allowed except in very exceptional circumstances which
              requires pre-approval.
            </CondensedLarge>
          </>
        ),
      });
    }
  },
};
