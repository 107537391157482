import {
  CandidatesConst,
  CandidateOpportunitiesConst,
  AxiomEmployeeType,
} from '@axiom/const';
import { arrayToConstMap } from '@axiom/utils';
import {
  Candidate,
  CandidateOpportunity,
  Opportunity,
  OpportunityCandidate,
} from '@axiom/validation';
import qs from 'qs';
import { BadgeBackgroundType } from '@axiom/ui';

import selectifyUtil from './selectify-util';

const { ProfileStatusLists, SortedProfileStatuses } = CandidatesConst;

const { CandidateStatuses, OppCandMlRecStatuses } = CandidateOpportunitiesConst;
type ProfileStatusListsType = {
  [key: string]: {
    name: string;
    value: [string];
  };
};

const profileStatusLists = Object.keys(ProfileStatusLists).reduce(
  // @ts-expect-error Needs new type
  (acc, k: 'allCertifiedTalent' | 'allProspects') => ({
    ...acc,
    [k]: {
      label: ProfileStatusLists[k].name,
      value: ProfileStatusLists[k].value,
    },
  }),
  {} as ProfileStatusListsType
);

const RemovedStatuses = new Set([
  CandidateStatuses.Cooled,
  CandidateStatuses.InterestedRejected,
  CandidateStatuses.Removed,
  CandidateStatuses.TalentOptOut,
  OppCandMlRecStatuses.AiRemoved,
]);

export const CandidateUtil = {
  getCandidatesListFilterDefaults() {
    return {
      axiomEmployeeType: [AxiomEmployeeType.Insourcing],
      profileStatus: [
        // @ts-expect-error Needs new type
        ...CandidateUtil.profileStatusLists.allCertifiedTalent.value,
      ],
    };
  },
  getCandidatesListFilters() {
    const currentQueryParams = qs.parse(window.location.search.slice(1));
    return Object.keys(currentQueryParams).length > 0
      ? currentQueryParams
      : CandidateUtil.getCandidatesListFilterDefaults();
  },
  profileFilterStatusOptions: [
    {
      label: ProfileStatusLists.allCertifiedTalent.name,
      value: ProfileStatusLists.allCertifiedTalent.value,
    },
    {
      label: ProfileStatusLists.allProspects.name,
      value: ProfileStatusLists.allProspects.value,
    },
    ...SortedProfileStatuses.map(k => ({
      label: k,
      value: k,
    })),
  ],
  getProfileStatusSelectItems() {
    const profileStatusGroup = 'filters.profileStatus';
    const profileStatusGroupLabel = 'Profile Status';
    return [
      {
        group: profileStatusGroup,
        groupLabel: profileStatusGroupLabel,
        // @ts-expect-error Needs new type
        ...profileStatusLists.allCertifiedTalent,
      },
      {
        group: profileStatusGroup,
        groupLabel: profileStatusGroupLabel,
        // @ts-expect-error Needs new type
        ...profileStatusLists.allProspects,
      },
      ...selectifyUtil({
        group: profileStatusGroup,
        groupLabel: profileStatusGroupLabel,
        options: arrayToConstMap(SortedProfileStatuses),
        sort: null,
      }),
    ];
  },
  isSubmittedFromWorkFeed: (
    candidateOpportunity: CandidateOpportunity,
    opportunity?: Opportunity
  ) => {
    if (opportunity?.directEligible === false) return false;

    return (
      candidateOpportunity.candidateStatus === CandidateStatuses.Submitted &&
      candidateOpportunity.previousCandidateStatus ===
        CandidateStatuses.Interested
    );
  },
  profileStatusLists,
  setConfidentialExperiences(candidate: Candidate, isAnon = false): Candidate {
    const data = { ...candidate };

    if (Array.isArray(candidate.experiences)) {
      data.experiences = candidate.experiences.map(exp => {
        if (!isAnon && exp.opportunity?.account?.isAccountConfidential) {
          exp.client = exp.opportunity.account.calculatedConfidentialName;
        } else if (exp.isConfidential || isAnon) {
          exp.client = exp.isAxiom ? 'Axiom Client' : 'Confidential Company';
        }

        return exp;
      });
    }

    return data;
  },
  formatYOE(years: string): string {
    const numYears = Number.parseInt(years, 10);
    let suffix = ' year';
    let prefix = '';

    if (numYears < 1) {
      prefix = '< ';
    } else if (numYears < 2) {
      suffix = '+ year';
    }
    if (numYears >= 2) suffix = '+ years';

    return `${prefix}${numYears >= 2 ? numYears : 1}${suffix}`;
  },
  getCandidateStatusBadgeColor(
    candidateStatus: CandidateOpportunity['candidateStatus']
  ): BadgeBackgroundType {
    switch (candidateStatus) {
      case CandidateStatuses.WarmedYes:
      case CandidateStatuses.Submitted:
      case CandidateStatuses.Interviewing: {
        return 'sky';
      }

      case CandidateStatuses.Selected:
      case CandidateStatuses.Engaged: {
        return 'green';
      }

      default: {
        return 'gray';
      }
    }
  },
  filterRemovedCandidates: (
    opportunityCandidates: Array<OpportunityCandidate | CandidateOpportunity>
  ): Array<OpportunityCandidate | CandidateOpportunity> => {
    return opportunityCandidates.filter(
      c => !RemovedStatuses.has(c.candidateStatus)
    );
  },
  getRemovedCandidates: (
    allCandidates: Array<OpportunityCandidate | CandidateOpportunity>
  ): Array<OpportunityCandidate | CandidateOpportunity> => {
    return allCandidates.filter(c => RemovedStatuses.has(c.candidateStatus));
  },
};
