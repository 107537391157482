import { Api } from '@axiom/ui';
import { Candidate, CandidateLegalTechSkill } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class CandidateLegalTechSkillsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  readLegalTechSkills(candidateId: Candidate['id']) {
    return super.read<{
      data: Array<CandidateLegalTechSkill>;
    }>({
      endpoint: `/candidates/${candidateId}/legalTechnicalSkills`,
      method: 'GET',
    });
  }
}

export const CandidateLegalTechSkillsApi =
  new CandidateLegalTechSkillsApiClass();
