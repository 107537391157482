import { Api } from '@axiom/ui';
import {
  Candidate,
  EnvoyCandidateSearchFilters,
  GroupedExperiences,
} from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

export type readCandidatesResponseType = {
  data: Array<Candidate>;
  meta: {
    resultCount: number;
    currentPage: number;
    pageCount: number;
  };
};

class CandidateApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  readCandidates(
    filterMeta: {
      filters?: EnvoyCandidateSearchFilters;
      sort?: string;
      page?: number;
    } = {
      page: 1,
    }
  ) {
    const { search, ...filtersExcludingSearch } = filterMeta?.filters ?? {
      search: undefined,
    };
    const meta = {
      filters: filtersExcludingSearch,
      sort: filterMeta.sort,
      page: filterMeta.page,
    };

    return super.read<readCandidatesResponseType>({
      endpoint: '/candidates/search',
      method: 'POST',
      body: {
        ...meta,
        search,
      },
    });
  }

  readCandidateGroupedExperiences(
    candidateId: Candidate['id'],
    query?: { isAnonymous: boolean }
  ) {
    return super.read<{ data: GroupedExperiences[] }>({
      endpoint: `/candidates/${candidateId}/groupedExperiences`,
      query,
      method: 'GET',
    });
  }

  refreshCandidates() {
    return super.invalidate('/candidates/search');
  }

  refreshCandidate(candidateId: Candidate['id']) {
    return super.invalidate(`/candidates/${candidateId}`);
  }
}

export const CandidateApi = new CandidateApiClass();
