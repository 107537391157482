export const DayWeekMode = {
  slots: 2,
  increments: 30,
} as const;

export const CalendarScheduleModes = {
  AVAILABILITY: 'availability',
  INTERVIEW: 'interview',
} as const;

export const CalendarScheduleModeOptions = [
  {
    id: CalendarScheduleModes.AVAILABILITY,
    text: 'Edit Availability',
  },
  {
    id: CalendarScheduleModes.INTERVIEW,
    text: 'Schedule Interview',
  },
] as const;
