import { Api } from '@axiom/ui';
import { User } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class UsersApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  updateUser(user: User) {
    return super.write<{ data: User }>({
      endpoint: `/users/${user.id}`,
      method: 'PATCH',
      body: user,
    });
  }
}

export const UsersApi = new UsersApiClass();
