import { z } from 'zod';
import { ZodArrayToEnum } from '@axiom/types';

import { eventPurposeOptions } from '../../stores/app-event-item-modal-store';

export const eventPurposes = eventPurposeOptions.map(
  option => option.value
) as Array<(typeof eventPurposeOptions)[number]['value']>;

export const activityLogFilterSchema = z.object({
  activities: z.array(z.enum(ZodArrayToEnum(eventPurposes))).nullish(),
  search: z.string().nullish(),
});

export type activityLogFilterSchemaType = z.infer<
  typeof activityLogFilterSchema
>;
