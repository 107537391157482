import React from 'react';
import {
  CandidateTaxonomyUtil,
  CondensedHeader,
  CondensedLarge,
  Grid,
  GridColumn,
  GridRow,
  Header4,
  Well,
  useApi,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import { TaxonomyApi } from '../../api/taxonomy-api';
import { CandidateLegalTechSkillsApi } from '../../api/candidate-legal-tech-skills-api';

export type TalentProfileDetailSkillsTypes = {
  candidateId: Candidate['id'];
};

export const TalentProfileDetailSkills = ({
  candidateId,
}: TalentProfileDetailSkillsTypes) => {
  const [{ data: taxonomy }, { data: legalTechSkills }] = useApi(
    TaxonomyApi.readTaxonomy(),
    CandidateLegalTechSkillsApi.readLegalTechSkills(candidateId)
  );

  const formattedLegalTechSkills =
    CandidateTaxonomyUtil.getDisplayableLegalTechSkills(legalTechSkills);

  return (
    <Grid name="TALENT_PROFILE_DETAIL_SKILLS">
      <GridRow>
        <GridColumn>
          <Header4>Skills</Header4>
        </GridColumn>
      </GridRow>
      <GridRow gutterVertical="16px">
        <GridColumn>
          <Well>
            <CondensedHeader>Legal Tech Skills</CondensedHeader>
            <CondensedLarge name="TALENT_PROFILE_DETAIL_LEGAL_TECH_SKILLS">
              {legalTechSkills?.length > 0 ? (
                <ul>
                  {Object.entries(formattedLegalTechSkills).map(
                    ([category, skills]) => (
                      <li
                        data-test={`TALENT_PROFILE_DETAIL_LEGAL_TECH_SKILLS_LIST_${category}`}
                        key={category}
                      >
                        {
                          taxonomy.legalTechSkillCategories[category]
                            .displayName
                        }
                        :{' '}
                        {skills
                          .map(
                            skill => taxonomy.legalTechSkills[skill].displayName
                          )
                          .join('; ')}
                      </li>
                    )
                  )}
                </ul>
              ) : (
                '--'
              )}
            </CondensedLarge>
          </Well>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
