import { UserShape } from '../models/user';
import GenericStore from '../classes/generic-store';
import { getLoggedUser } from '../api/users-legacy-api';

class UserStoreClass extends GenericStore {
  load(activeDirectorySecurityGroup) {
    return this.setState(
      getLoggedUser().then(u => ({
        ...u,
        activeDirectorySecurityGroup,
      }))
    );
  }

  getDataShape() {
    return UserShape;
  }

  select(state) {
    return super.select(state).data || null;
  }

  isAiMatchControlEnabled(user) {
    return user?.userSettings?.find(
      us => us.name === 'enable-ml-button' && us.value === 'true'
    );
  }
}

export const PreloadedUserStore = new UserStoreClass();
