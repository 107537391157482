import React from 'react';

import { CellRenderProps, SortType } from './data-grid-props';

export type DataGridHeaderProps<T> = {
  name: string;
  cellRender?: (props: CellRenderProps<T>) => React.ReactNode;
  displayName?: string | (() => JSX.Element | null) | null;
  FiltersRender?: () => JSX.Element;
  pinned?: 'left' | 'right';
  lockPinned?: boolean;
  sortingOrder?: Array<SortType>;
  initialSort?: SortType;
  width?: number;
  stretched?: boolean;
  onCellClicked?: (data: Record<string, unknown>) => void;
};

export const DataGridHeader = <T,>({
  name,
  sortingOrder,
}: DataGridHeaderProps<T>) => {
  return (
    <div data-test={name} data-sorting-order={JSON.stringify(sortingOrder)}>
      <div className="header-text" />
    </div>
  );
};
