import React from 'react';
import {
  Badge,
  Card,
  CardSection,
  CondensedLabel,
  CondensedMedium,
  DateUtil,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Layout,
  LayoutItem,
  Paragraph,
  SectionHeader,
  Tooltip,
} from '@axiom/ui';
import {
  CandidateOpportunitiesConst,
  CandidateStatusLabelsMap,
  PositionsConst,
  WeeklyTimeUnits,
} from '@axiom/const';
import { OpportunityCandidate, Position } from '@axiom/validation';

import { CandidateUtil } from '../../utils/candidate-util';

import { TalentOpportunityCardContextMenu } from './TalentOpportunityCardContextMenu';

const { EndDateStatuses } = PositionsConst;
const { CandidateStatuses, CandidateStatusLabels: CandidateStatusLabelsConst } =
  CandidateOpportunitiesConst;
const CandidateStatusLabels: Record<string, string> =
  CandidateStatusLabelsConst;

export const TalentOpportunityCardTypeNames = {
  inConsideration: 'In Consideration',
  currentEngagements: 'Current Engagements',
  previousEngagements: 'Previous Engagements',
  noLongerInConsideration: 'No Longer in Consideration',
} as const;

const TalentOpportunityCardTypes = Object.values(
  TalentOpportunityCardTypeNames
);

const DASH = '-';

export type TalentOpportunityCardType =
  (typeof TalentOpportunityCardTypes)[number];

export const talentOpportunityCardTypeNameFormatter = (
  type: TalentOpportunityCardType
) => {
  return type.replaceAll(' ', '-').toLowerCase();
};

const formatCreateOrUpdateDate = (date: string, isCreate: boolean) => {
  if (date) {
    const displayDate = DateUtil.displayTimestamp(date);
    const daysOld = DateUtil.getCountFromDateToDate(
      new Date().toISOString(),
      date
    );

    if (daysOld === 0) {
      return displayDate;
    } else if (daysOld === 1) {
      return `${displayDate} (1 day ${isCreate ? 'old' : 'ago'})`;
    }
    return `${displayDate} (${daysOld} days ${isCreate ? 'old' : 'ago'})`;
  }
  return DASH;
};

const formatWeeklyTimeUnit = (weeklyTimeUnit: string) => {
  if (weeklyTimeUnit === WeeklyTimeUnits.Daily) {
    return 'Days/Week';
  }
  return 'Hours/Week';
};

const formatBilling = (position: Position) => {
  if (position?.billingUnitsPerWeek) {
    return `${position.billingUnitsPerWeek} ${formatWeeklyTimeUnit(
      position.weeklyTimeUnit
    )} ${position.reservedType ? `(${position.reservedType})` : ''}`;
  }
  return DASH;
};

const formatChangedStatus = (opportunityCandidate: OpportunityCandidate) => {
  const date = DateUtil.displayTimestamp(
    opportunityCandidate.candidateStatusUpdatedAt
  );

  if (date) {
    if (opportunityCandidate.previousCandidateStatus) {
      return `${date} (previously ${
        CandidateStatusLabelsMap.get(
          opportunityCandidate.previousCandidateStatus
        ) || 'N/A'
      })`;
    }
    return `${date} (previously N/A)`;
  }
  return DASH;
};

const formatCompensation = (opportunityCandidate: OpportunityCandidate) => {
  if (opportunityCandidate.proposedHourlyCompensation) {
    let rate = '';
    if (opportunityCandidate.compensationType) {
      rate += `${opportunityCandidate.compensationType}, `;
    }

    rate += `${opportunityCandidate.proposedHourlyCompensation}`;

    if (opportunityCandidate.currency) {
      rate += ` ${opportunityCandidate.currency}`;
    }

    rate += '/Hourly';

    if (opportunityCandidate.proposedMarginTarget) {
      rate += ` (${opportunityCandidate.proposedMarginTarget}%)`;
    }
    return rate;
  }
  return DASH;
};

const isConsiderationCard = (type: TalentOpportunityCardType) => {
  return (
    type === TalentOpportunityCardTypeNames.inConsideration ||
    type === TalentOpportunityCardTypeNames.noLongerInConsideration
  );
};

const isEngagementCard = (type: TalentOpportunityCardType) => {
  return (
    type === TalentOpportunityCardTypeNames.currentEngagements ||
    type === TalentOpportunityCardTypeNames.previousEngagements
  );
};

export const TalentOpportunityCard = ({
  type,
  opportunityCandidate,
}: {
  type: TalentOpportunityCardType;
  opportunityCandidate: OpportunityCandidate;
}) => {
  let talentOnPosition: string;
  if (
    isConsiderationCard(type) &&
    opportunityCandidate.position?.otherCandidatesStatusOnPosition
  ) {
    const getCandidatesWithStatus = (
      status: string,
      includeNames = false
    ): string | undefined => {
      const candidateList =
        opportunityCandidate.position.otherCandidatesStatusOnPosition.filter(
          c => c.status === status
        );

      if (candidateList.length > 0) {
        let returnValue = `${candidateList.length} ${CandidateStatusLabels[status]}`;
        if (includeNames) {
          returnValue += ` (${candidateList
            .map(c => c.candidateName)
            .join(', ')})`;
        }

        return returnValue;
      }
      return undefined;
    };

    talentOnPosition = [
      getCandidatesWithStatus(CandidateStatuses.Completed, true),
      getCandidatesWithStatus(CandidateStatuses.Engaged, true),
      getCandidatesWithStatus(CandidateStatuses.Selected, true),
      getCandidatesWithStatus(CandidateStatuses.Interviewing),
      getCandidatesWithStatus(CandidateStatuses.Submitted),
      getCandidatesWithStatus(CandidateStatuses.WarmedYes),
      getCandidatesWithStatus(CandidateStatuses.Warmed),
    ]
      .filter(Boolean)
      .join(', ');
  }

  return (
    <Card name={`${opportunityCandidate.id}-card`}>
      <CardSection key={`${opportunityCandidate.id}`}>
        <Grid columns={2}>
          <GridRow>
            <GridColumn>
              <SectionHeader name="jobname-header" maxLines={1}>
                {opportunityCandidate.jobName}
              </SectionHeader>
            </GridColumn>
            <GridColumn>
              <Layout position="right">
                <LayoutItem>
                  <Badge
                    background={CandidateUtil.getCandidateStatusBadgeColor(
                      opportunityCandidate.candidateStatus
                    )}
                    name="candidateStatusBadge"
                  >
                    <CondensedMedium>
                      {CandidateStatusLabelsMap.get(
                        opportunityCandidate.candidateStatus
                      )}
                    </CondensedMedium>
                  </Badge>
                </LayoutItem>
                <LayoutItem position="center">
                  <TalentOpportunityCardContextMenu
                    opportunityCandidate={opportunityCandidate}
                  />
                </LayoutItem>
              </Layout>
            </GridColumn>
          </GridRow>
        </Grid>
        <Gutter vertical="8px" />
        <Grid columns={2}>
          <GridRow>
            <GridColumn>
              <Layout direction="vertical">
                {isConsiderationCard(type) && (
                  <Paragraph name="created-field">
                    <CondensedLabel>Created: </CondensedLabel>
                    <CondensedMedium as="span">
                      {formatCreateOrUpdateDate(
                        opportunityCandidate.createdAt,
                        true
                      )}
                    </CondensedMedium>
                  </Paragraph>
                )}
                <Paragraph name="billing-field">
                  <CondensedLabel>Billing: </CondensedLabel>
                  <CondensedMedium as="span">
                    {formatBilling(opportunityCandidate.position)}
                  </CondensedMedium>
                </Paragraph>
                <Paragraph name="account-field">
                  <CondensedLabel>Account: </CondensedLabel>
                  <CondensedMedium as="span">
                    {opportunityCandidate.accountName || DASH}
                  </CondensedMedium>
                </Paragraph>
                <Paragraph name="ts-lead-field">
                  <CondensedLabel>TS lead: </CondensedLabel>
                  <CondensedMedium as="span">
                    {opportunityCandidate.ownerUser?.fullName || DASH}
                  </CondensedMedium>
                </Paragraph>
                {isConsiderationCard(type) && (
                  <Paragraph name="opportunity-owner-field">
                    <CondensedLabel>Opportunity owner: </CondensedLabel>
                    <CondensedMedium as="span">
                      {opportunityCandidate.salesLead?.fullName || DASH}
                    </CondensedMedium>
                  </Paragraph>
                )}
                {isEngagementCard(type) && (
                  <Paragraph name="account-owner-field">
                    <CondensedLabel>Account owner: </CondensedLabel>
                    <CondensedMedium as="span">
                      {opportunityCandidate.account.ownerUser?.fullName || DASH}
                    </CondensedMedium>
                  </Paragraph>
                )}
              </Layout>
            </GridColumn>
            <GridColumn>
              <Layout direction="vertical">
                {isConsiderationCard(type) && (
                  <>
                    <Paragraph name="changed-status-field">
                      <CondensedLabel>Changed status: </CondensedLabel>
                      <CondensedMedium as="span">
                        {formatChangedStatus(opportunityCandidate)}
                      </CondensedMedium>
                    </Paragraph>
                    <Paragraph name="talent-on-position-field">
                      <CondensedLabel>
                        Other talent on position:{' '}
                      </CondensedLabel>
                      <CondensedMedium
                        name="talent-on-position-value"
                        as="span"
                      >
                        {talentOnPosition || DASH}
                      </CondensedMedium>
                    </Paragraph>
                    <Paragraph name="last-updated-field">
                      <CondensedLabel>Last updated: </CondensedLabel>
                      <CondensedMedium as="span">
                        {formatCreateOrUpdateDate(
                          opportunityCandidate.updatedAt,
                          false
                        )}
                      </CondensedMedium>
                    </Paragraph>
                  </>
                )}
                {isEngagementCard(type) && (
                  <>
                    <Paragraph name="start-date-field">
                      <CondensedLabel>Start date: </CondensedLabel>
                      <CondensedMedium as="span">
                        {DateUtil.displayDayAndDate(
                          opportunityCandidate.position?.startDate
                        ) || DASH}
                      </CondensedMedium>
                    </Paragraph>
                    <Paragraph name="roll-off-field">
                      <CondensedLabel>Roll-off: </CondensedLabel>
                      <CondensedMedium as="span">
                        {opportunityCandidate.position?.endDateStatus ===
                        EndDateStatuses.Confirmed ? (
                          <Badge name="roll-off-badge" background="yellow">
                            {EndDateStatuses.Confirmed}
                          </Badge>
                        ) : (
                          EndDateStatuses.NotConfirmed
                        )}{' '}
                        {DateUtil.displayDayAndDate(
                          opportunityCandidate.position.endDate
                        )}
                      </CondensedMedium>
                    </Paragraph>
                    <Paragraph>
                      <CondensedLabel>Original compensation:</CondensedLabel>
                      <Tooltip name="original-compensation-tooltip">
                        Original compensation is set when the opportunity is
                        created and may not reflect subsequent rate changes or
                        corrections.
                      </Tooltip>
                      <CondensedMedium
                        as="span"
                        name="original-compensation-field"
                      >
                        {formatCompensation(opportunityCandidate)}
                      </CondensedMedium>
                    </Paragraph>
                    <Paragraph name="id-field">
                      <CondensedLabel>ID: </CondensedLabel>
                      <CondensedMedium as="span">
                        {opportunityCandidate.integrationId}
                      </CondensedMedium>
                    </Paragraph>
                  </>
                )}
              </Layout>
            </GridColumn>
          </GridRow>
        </Grid>
      </CardSection>
    </Card>
  );
};
