import React, { useState } from 'react';
import {
  Button,
  CondensedMedium,
  DateUtil,
  GridColumn,
  GridRow,
  Gutter,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  ParagraphHeader,
  Suspense,
} from '@axiom/ui';
import { ContactLegacy } from '@axiom/validation';

import { UsersLegacyApi } from '../../api/users-legacy-api';
import { ContactStore } from '../../stores/contact-store';
import { UsersApi } from '../../api/users-old-api';

export const ResendAltWelcomeEmail = ({
  contact,
}: {
  contact: ContactLegacy;
}) => {
  const [confirmResendWelcomeEmail, setConfirmResendWelcomeEmail] =
    useState(false);

  const handleResendWelcomeEmail = () => {
    setConfirmResendWelcomeEmail(true);
  };

  const handleSendClick = async () => {
    await UsersApi.writeResendUserWelcomeEmail(contact.id);

    UsersLegacyApi.refreshUserById(contact.user.id);
    ContactStore.load(contact.id);
    setConfirmResendWelcomeEmail(false);
  };

  const getMessage = () => {
    let data = 'Email has not been sent yet';

    if (contact.welcomeEmailLastSentAt) {
      data = `Last sent on ${DateUtil.displayDate(contact.welcomeEmailLastSentAt)} by `;
      data +=
        contact.welcomeEmailLastSentByUser === null
          ? 'Axiom'
          : `${contact.welcomeEmailLastSentByUser.firstName} ${contact.welcomeEmailLastSentByUser.lastName}`;
    }

    return data;
  };

  return (
    <>
      <GridRow gutterBottom="24px">
        <GridColumn width={5}>
          <ParagraphHeader>
            Welcome to Access Legal Talent Email
          </ParagraphHeader>
        </GridColumn>
        <GridColumn name="RESEND_WELCOME_EMAIL" width={7}>
          {contact.accounts.length > 0 && contact.user.isActive && (
            <Gutter bottom="16px">
              <Button
                name="RESEND_BTN"
                pattern="secondary"
                onClick={handleResendWelcomeEmail}
              >
                Resend email now
              </Button>
            </Gutter>
          )}
          <CondensedMedium name="MESSAGE">{getMessage()}</CondensedMedium>
        </GridColumn>
      </GridRow>
      {confirmResendWelcomeEmail && (
        <Suspense>
          <Modal size="confirm" name="RESEND_CONFIRM_MODAL">
            <ModalHeader
              name="MODAL_HEADER"
              onClose={() => setConfirmResendWelcomeEmail(false)}
            >
              Resend Welcome to Access Legal Talent Email
            </ModalHeader>
            <ModalSection name="MODAL_MESSAGE">
              {contact.user.firstName} {contact.user.lastName}{' '}
              {contact.user.lastLoginAt
                ? `last logged in on ${DateUtil.displayDate(contact.user.lastLoginAt)}.`
                : 'has never logged in.'}
              <br />
              <br />
              Do you wish to continue?
            </ModalSection>
            <ModalFooter>
              <Button
                variation="outline"
                name="CANCEL"
                onClick={() => setConfirmResendWelcomeEmail(false)}
              >
                Cancel
              </Button>
              <Button name="SEND" onClick={handleSendClick}>
                Yes, Send now
              </Button>
            </ModalFooter>
          </Modal>
        </Suspense>
      )}
    </>
  );
};
