import { UserSetting } from '@axiom/validation';

import { PreloadedUserStore } from '../stores/preloaded-user-store';
import { UserSettingsApi } from '../api/user-setting-api';
import { UsersLegacyApi } from '../api/users-legacy-api';

export const UserSettingsUtil = {
  shouldShowNewOpportunityTalentView: (settings: UserSetting[] = []) =>
    !settings.some(
      s => s.name === 'show-new-opportunity-talent-view' && s.value === 'false'
    ),
  updateShowNewOpportunityTalentViewSetting: async (value: boolean) => {
    await UserSettingsApi.updateUserSetting({
      name: 'show-new-opportunity-talent-view',
      value: value.toString(),
    });

    PreloadedUserStore.load();
    await UsersLegacyApi.refreshSessionUser();
  },
};
