import React from 'react';
import { CandidatesConst } from '@axiom/const';
import {
  Modal,
  ModalHeader,
  ModalSection,
  ModalFooter,
  Button,
  ParagraphHeader,
  Paragraph,
  Gutter,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';

const {
  ProfileStatuses: { PendingAlum, PendingAlumDNR },
} = CandidatesConst;

export const TalentProfilePendingAlumModal = ({
  onCancel,
  onSave,
  profileStatus,
}: {
  onCancel: () => void;
  onSave: () => void;
  profileStatus: Candidate['profileStatus'];
}) => {
  const modalContent = {
    [PendingAlum]: {
      title: 'Alum Talent',
      subhead: 'Alum this talent?',
      body: 'Setting this talent to Alum changes their profile status to Pending Alum and then to Alum when all active engagements are complete. Please confirm that you want to Alum this talent.',
    },
    [PendingAlumDNR]: {
      title: 'Alum DNR Talent',
      subhead: 'Alum DNR this talent?',
      body: 'Setting this talent to Alum DNR changes their profile status to Pending Alum DNR and then to Alum DNR when all active engagements are complete. Please confirm that you want to Alum DNR this talent.',
    },
  } as Record<
    Candidate['profileStatus'],
    { title: string; subhead: string; body: string }
  >;

  if (!modalContent?.[profileStatus]) {
    return null;
  }
  return (
    <Modal name="ALUM_STATUS_MODAL">
      <ModalHeader onClose={onCancel}>
        {modalContent[profileStatus].title}
      </ModalHeader>
      <ModalSection>
        <ParagraphHeader>{modalContent[profileStatus].subhead}</ParagraphHeader>
        <Gutter bottom="8px" />
        <Paragraph>{modalContent[profileStatus].body}</Paragraph>
      </ModalSection>
      <ModalFooter>
        <Button onClick={onCancel} name="CANCEL">
          Cancel
        </Button>
        <Button onClick={onSave} name="SAVE">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
