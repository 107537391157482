import {
  CondensedHeader,
  CondensedLarge,
  Flashy,
  Icon,
  Layout,
  LayoutItem,
} from '@axiom/ui';
import { TopTask } from '@axiom/validation';
import React from 'react';

import { TopTasksUtil } from '../../utils/top-tasks-util';

export const WorkspaceTopTaskTile = ({ task }: { task: TopTask }) => {
  const tileInfo = TopTasksUtil.getTopTaskCopy(task);

  return (
    <Layout>
      <LayoutItem rightGutter="8px" position="center" verticalGutter="16px">
        <Flashy
          name="TOP_TASK_BADGE"
          color={TopTasksUtil.getTopTaskBadgeColor(
            task.taskExpectedCompletionDateTime
          )}
        >
          <Icon name="alert-filled" />
        </Flashy>
      </LayoutItem>
      <LayoutItem topGutter="4px">
        <CondensedHeader name="TOP_TASK_HEADER" maxLines={2}>
          {tileInfo.header}
        </CondensedHeader>
        <CondensedLarge name="TOP_TASK_DESCRIPTION">
          {tileInfo.description}
        </CondensedLarge>
      </LayoutItem>
    </Layout>
  );
};
